import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import {CheckCircle, Info, User, XCircle} from 'react-feather';
import Tabs from '../../../components/tabs';

import {default as Summary} from './Tab_Summary';


class DetailView extends Component {
    state = {};

    render() {
        let user = [];

        return (
            <div className='bg-light'>
                <div className='card table-card border-0 shadow mx-3 mt-3 user-card'>
                    <div className='text-mute pt-3 pl-3'>
                        <small className='text-mute'>b2c &gt; View</small>
                    </div>

                    <div className='profile p-3 d-md-flex flex-row align-items-center justify-content-between'>
                        <div className='d-md-flex flex-row align-items-center'>
                            <div
                                className='border avatar-lg bg-light d-flex flex-row align-items-center justify-content-center'>
                                <div className='user-avatar d-flex flex-row align-items-center justify-content-center'>
                                    <User></User>
                                </div>
                            </div>
                            <div className='ml-md-4 my-3 my-md-0'>
                                <h4 className='text-capitalize'>
                                    Detail View
                                </h4>
                                {/*<div>Id: <code>{this.state.id}</code></div>*/}
                                {/*<div>Name: <code>{this.state.name}</code></div>*/}
                                <div>Description: <code>{this.state.description}</code></div>
                                <div className='ml-2 mt-1'>
                                    <span className='badge badge-secondary px-2'>**</span>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-row flex-wrap member-view '>
                            <div className='d-md-flex flex-row text-center flex-wrap admin-actions justify-content-end'>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='position-relative px-3'>
                    {this.state.id && (
                        <Tabs>
                            <Route
                                permission='all'
                                path={`/management/view/b2c/${this.props.match.params.id}/details`}
                                title='Data'>
                                <Summary data={this.state}/>
                            </Route>


                        </Tabs>
                    )}
                </div>

            </div>
        );
    }

    componentDidMount = () => {
        this.fetchData();
    };

    fetchData = () => {
        console.debug("ZZZZ>>", this.props.match.params)
        window.app
            .service('core/b2c')
            .find({
                query: {
                    id: this.props.match.params.id
                }
            })
            .then((response) => {
                console.debug(response)
                response = response.data[0]
                console.debug("Record>>", response)

                this.setState(response);
            });
    };

    updateRecord = (data) => {
        window.alert2.show({
            loader: true,
            title: 'Updating b2c Data. Please Wait',
            buttons: false,
        });

        window.app
            .service('core/b2c')
            .patch(this.state.id, data)
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    msisdn: this.state.msisdn,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Updated Successfully',
                    buttons: true,
                    onSubmit: () => {
                        this.props.history.push('management/pipelines/' + response.id + '/details');
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                console.debug(err);
                if (err.code !== 500) {
                    window.alert2.show({
                        loader: false,
                        icon: <Info size={60}></Info>,
                        title: 'MakerChecker Alerts',
                        message: err.message,
                        buttons: true,
                        onSubmit: () => {
                            window.location.reload();
                        },
                    });

                    return;
                }

                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
            });
    };


}

export default DetailView;
