import React, {Component} from 'react';
import {Code, Database, DollarSign, Users, Activity, Grid, Shield, UserCheck,} from 'react-feather';
import Access from "./components/accessManager";
import {Route} from "react-router-dom";
import Home from "./pages/dashboard/home";
import Preview from "./pages/dashboard/preview";
// import Preview2 from "./pages/dashboard/preview2";
import ExecutivePreview from "./pages/dashboard/executive_preview";
// import ExecutivePreview2 from "./pages/dashboard/executive_preview2";


import {default as Admins} from "./pages/admin/admins";
import Roles from "./pages/admin/roles";
import AuditTrail from "./pages/audit/audit";
import AuditData from "./pages/audit/auditData";
import IPAdress from "./pages/admin/ip_address";
import AdminCreate from "./pages/admin/adminCreate";
import MakerChecker from "./pages/admin/makerChecker";
import Dashboards from "./pages/dashboard/dashboards";
import MakerCheckerLogs from "./pages/admin/makerCheckerLogs";
import GroupsIcon from '@mui/icons-material/Groups';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import AutoModeIcon from '@mui/icons-material/AutoMode';

import EditIcon from '@mui/icons-material/Edit';

import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import SettingsIcon from '@mui/icons-material/Settings';
import TESTVIEWTEMPLATE from "./pages/core/TESTVIEWTEMPLATE";
import GroupWorkIcon from '@mui/icons-material/GroupWork';

import Coreaccount_cummulativeService from "./pages/core/account_cummulative/index";
import Coreaccount_cummulativeDetailService from "./pages/core/account_cummulative/DetailView";
import Coreagent_typeService from "./pages/core/agent_type/index";
import Coreagent_typeDetailService from "./pages/core/agent_type/DetailView";
import Coreapi_activity_logService from "./pages/core/api_activity_log/index";
import Coreapi_activity_logDetailService from "./pages/core/api_activity_log/DetailView";
import Coreapi_userService from "./pages/core/api_user/index";
import Coreapi_userDetailService from "./pages/core/api_user/DetailView";
import Coreb2b_resultsService from "./pages/core/b2b_results/index";
import Coreb2b_resultsDetailService from "./pages/core/b2b_results/DetailView";
import Coreb2cService from "./pages/core/b2c/index";
import Coreb2cDetailService from "./pages/core/b2c/DetailView";
import Coreb2c_archiveService from "./pages/core/b2c_archive/index";
import Coreb2c_archiveDetailService from "./pages/core/b2c_archive/DetailView";
import Coreb2c_resultsService from "./pages/core/b2c_results/index";
import Coreb2c_resultsDetailService from "./pages/core/b2c_results/DetailView";
import Coreb2c_walletService from "./pages/core/b2c_wallet/index";
import Coreb2c_walletDetailService from "./pages/core/b2c_wallet/DetailView";
import CorebranchService from "./pages/core/branch/index";
import CorebranchDetailService from "./pages/core/branch/DetailView";
import Corec2b_confirmationService from "./pages/core/c2b_confirmation/index";
import Corec2b_confirmationDetailService from "./pages/core/c2b_confirmation/DetailView";
import Corec2b_wrapperService from "./pages/core/c2b_wrapper/index";
import Corec2b_wrapperDetailService from "./pages/core/c2b_wrapper/DetailView";
import Corec2b_wrapper_archiveService from "./pages/core/c2b_wrapper_archive/index";
import Corec2b_wrapper_archiveDetailService from "./pages/core/c2b_wrapper_archive/DetailView";
import CorecurrencyService from "./pages/core/currency/index";
import CorecurrencyDetailService from "./pages/core/currency/DetailView";
import CorecustomerService from "./pages/core/customer/index";
import CorecustomerDetailService from "./pages/core/customer/DetailView";
import CoredbcdrService from "./pages/core/dbcdr/index";
import CoredbcdrDetailService from "./pages/core/dbcdr/DetailView";
import CoredbqueueService from "./pages/core/dbqueue/index";
import CoredbqueueDetailService from "./pages/core/dbqueue/DetailView";
import Coredbqueue_bakService from "./pages/core/dbqueue_bak/index";
import Coredbqueue_bakDetailService from "./pages/core/dbqueue_bak/DetailView";
import Coreeligibility_checkService from "./pages/core/eligibility_check/index";
import Coreeligibility_checkDetailService from "./pages/core/eligibility_check/DetailView";
import CoreentityService from "./pages/core/entity/index";
import CoreentityDetailService from "./pages/core/entity/DetailView";
import Coreentity_accountService from "./pages/core/entity_account/index";
import Coreentity_accountDetailService from "./pages/core/entity_account/DetailView";
import CoreforexService from "./pages/core/forex/index";
import CoreforexDetailService from "./pages/core/forex/DetailView";
import Coreforex_transaction_logService from "./pages/core/forex_transaction_log/index";
import Coreforex_transaction_logDetailService from "./pages/core/forex_transaction_log/DetailView";
import CoreimageService from "./pages/core/image/index";
import CoreimageDetailService from "./pages/core/image/DetailView";
import Coreinsight_pgw_respService from "./pages/core/insight_pgw_resp/index";
import Coreinsight_pgw_respDetailService from "./pages/core/insight_pgw_resp/DetailView";
import CorelanguageService from "./pages/core/language/index";
import CorelanguageDetailService from "./pages/core/language/DetailView";
import Coreloan_accountService from "./pages/core/loan_account/index";
import Coreloan_accountDetailService from "./pages/core/loan_account/DetailView";
import Coreloan_eligibilityService from "./pages/core/loan_eligibility/index";
import Coreloan_eligibilityDetailService from "./pages/core/loan_eligibility/DetailView";
import Coreloan_interest_earnedService from "./pages/core/loan_interest_earned/index";
import Coreloan_interest_earnedDetailService from "./pages/core/loan_interest_earned/DetailView";
import Coreloan_productService from "./pages/core/loan_product/index";
import Coreloan_productDetailService from "./pages/core/loan_product/DetailView";
import Coreloan_scheduleService from "./pages/core/loan_schedule/index";
import Coreloan_scheduleDetailService from "./pages/core/loan_schedule/DetailView";
import Corempesa_disburseService from "./pages/core/mpesa_disburse/index";
import Corempesa_disburseDetailService from "./pages/core/mpesa_disburse/DetailView";
import Corempesa_disburse_completeService from "./pages/core/mpesa_disburse_complete/index";
import Corempesa_disburse_completeDetailService from "./pages/core/mpesa_disburse_complete/DetailView";
import Corenext_of_kinService from "./pages/core/next_of_kin/index";
import Corenext_of_kinDetailService from "./pages/core/next_of_kin/DetailView";
import CorerepaymentService from "./pages/core/repayment/index";
import CorerepaymentDetailService from "./pages/core/repayment/DetailView";
import Corerevenue_shareService from "./pages/core/revenue_share/index";
import Corerevenue_shareDetailService from "./pages/core/revenue_share/DetailView";
import Corerevenue_share_logService from "./pages/core/revenue_share_log/index";
import Corerevenue_share_logDetailService from "./pages/core/revenue_share_log/DetailView";
import Coresavings_accountService from "./pages/core/savings_account/index";
import Coresavings_accountDetailService from "./pages/core/savings_account/DetailView";
import Coresavings_interest_earnedService from "./pages/core/savings_interest_earned/index";
import Coresavings_interest_earnedDetailService from "./pages/core/savings_interest_earned/DetailView";
import Coresavings_interest_paymentService from "./pages/core/savings_interest_payment/index";
import Coresavings_interest_paymentDetailService from "./pages/core/savings_interest_payment/DetailView";
import Coresavings_productService from "./pages/core/savings_product/index";
import Coresavings_productDetailService from "./pages/core/savings_product/DetailView";
import CorestatusService from "./pages/core/status/index";
import CorestatusDetailService from "./pages/core/status/DetailView";
import CoresuspenseService from "./pages/core/suspense/index";
import CoresuspenseDetailService from "./pages/core/suspense/DetailView";
import Coretransaction_logService from "./pages/core/transaction_log/index";
import Coretransaction_logDetailService from "./pages/core/transaction_log/DetailView";
import CoreuserService from "./pages/core/user/index";
import CoreuserDetailService from "./pages/core/user/DetailView";


import FocuscustomerService from "./pages/focus/customer/index";
import FocuscustomerDetailService from "./pages/focus/customer/DetailView";

import FocusofficialsService from "./pages/focus/officials/index";
import FocusofficialsDetailService from "./pages/focus/officials/DetailView";

import Focusloan_accountService from "./pages/focus/loan_account/index";
import Focusloan_accountDetailService from "./pages/focus/loan_account/DetailView";
import Focusloan_eligibilityService from "./pages/focus/loan_eligibility/index";
import Focusloan_eligibilityDetailService from "./pages/focus/loan_eligibility/DetailView";

import Focussavings_accountService from "./pages/focus/savings_account/index";
import Focussavings_accountDetailService from "./pages/focus/savings_account/DetailView";

import Focusloan_productService from "./pages/focus/loan_product/index";
import Focusloan_productDetailService from "./pages/focus/loan_product/DetailView";

import Focussavings_productService from "./pages/focus/savings_product/index";
import Focussavings_productDetailService from "./pages/focus/savings_product/DetailView";


import Redirect from "react-router-dom/es/Redirect";

export class NavRoutes extends Component {
    render() {
        return (
            <div className='d-flex flex-fill flex-column'>
                <Access permission='all'>
                    <Route path='/' exact component={Home}/>
                </Access>

                <Access permission='all'>
                    <Route path='/home' exact component={Home}/>
                </Access>

                <Access permission='VIEW_ANALYTICS'>
                    <Route path='/management/analytics' exact component={Preview}/>
                </Access>

                {/*<Access permission='VIEW_ANALYTICS'>*/}
                {/*    <Route path='/management/analytics2' exact component={Preview2}/>*/}
                {/*</Access>*/}
                <Access permission='VIEW_EXECUTIVE_ANALYTICS'>
                    <Route path='/management/executive_analytics' exact component={ExecutivePreview}/>
                </Access>

                {/*<Access permission='VIEW_EXECUTIVE_ANALYTICS'>*/}
                {/*    <Route path='/management/executive_analytics2' exact component={ExecutivePreview2}/>*/}
                {/*</Access>*/}

                <Access permission='ADMIN_READ'>
                    <Route path='/administration/admins' exact component={Admins}/>
                </Access>

                <Access permission='ROLES_READ'>
                    <Route path='/administration/roles' exact component={Roles}/>
                </Access>


                <Access permission='AUDIT_READ'>
                    <Route path='/administration/audit' exact component={AuditTrail}/>
                </Access>

                <Access permission='AUDIT_READ'>
                    <Route path='/administration/audit-data' exact component={AuditData}/>
                </Access>


                <Access permission='IP_ADDRESS_READ'>
                    <Route path='/administration/ip' exact component={IPAdress}/>
                </Access>


                <Access permission='ADMIN_CREATE'>
                    <Route path='/administration/admin-create' exact component={AdminCreate}/>
                </Access>

                <Access permission='MAKER_CHECKER_READ'>
                    <Route path='/administration/maker-checker' exact component={MakerChecker}/>
                </Access>

                <Access permission='all'>
                    <Route path='/dashboards/:type' exact component={Dashboards}/>
                </Access>
                <Access permission='MAKER_CHECKER_CONFIRM'>
                    <Route
                        path='/administration/maker-checker/:id/:status'
                        exact
                        component={MakerCheckerLogs}
                    />
                </Access>
                <Access permission='all'><Route path='/management/focus/customer'
                                                component={FocuscustomerService}/></Access>
                <Access permission='all'><Route path='/management/focus/view/customer/:id/:tab'
                                                component={FocuscustomerDetailService}/></Access>

                <Access permission='all'><Route path='/management/focus/officials'
                                                component={FocusofficialsService}/></Access>
                <Access permission='all'><Route path='/management/focus/view/officials/:id/:tab'
                                                component={FocusofficialsDetailService}/></Access>


                <Access permission='all'><Route path='/management/focus/loan_account'
                                                component={Focusloan_accountService}/></Access>
                <Access permission='all'><Route path='/management/focus/view/loan_account/:id/:tab'
                                                component={Focusloan_accountDetailService}/></Access>

                <Access permission='all'><Route path='/management/focus/loan_eligibility'
                                                component={Focusloan_eligibilityService}/></Access>
                <Access permission='all'><Route path='/management/focus/view/loan_eligibility/:id/:tab'
                                                component={Focusloan_eligibilityDetailService}/></Access>

                <Access permission='all'><Route path='/management/focus/savings_account'
                                                component={Focussavings_accountService}/></Access>
                <Access permission='all'><Route path='/management/focus/view/savings_account/:id/:tab'
                                                component={Focussavings_accountDetailService}/></Access>

                <Access permission='all'><Route path='/management/focus/loan_product'
                                                component={Focusloan_productService}/></Access>
                <Access permission='all'><Route path='/management/focus/view/loan_product/:id/:tab'
                                                component={Focusloan_productDetailService}/></Access>

                <Access permission='all'><Route path='/management/focus/savings_product'
                                                component={Focussavings_productService}/></Access>
                <Access permission='all'><Route path='/management/focus/view/savings_product/:id/:tab'
                                                component={Focussavings_productDetailService}/></Access>

                <Access permission='all'><Route path='/management/account_cummulative' component={Coreaccount_cummulativeService}/></Access>
                <Access permission='all'><Route path='/management/view/account_cummulative/:id/:tab' component={Coreaccount_cummulativeDetailService}/></Access>
                <Access permission='all'><Route path='/management/agent_type' component={Coreagent_typeService}/></Access>
                <Access permission='all'><Route path='/management/view/agent_type/:id/:tab' component={Coreagent_typeDetailService}/></Access>
                <Access permission='all'><Route path='/management/api_activity_log' component={Coreapi_activity_logService}/></Access>
                <Access permission='all'><Route path='/management/view/api_activity_log/:id/:tab' component={Coreapi_activity_logDetailService}/></Access>
                <Access permission='all'><Route path='/management/api_user' component={Coreapi_userService}/></Access>
                <Access permission='all'><Route path='/management/view/api_user/:id/:tab' component={Coreapi_userDetailService}/></Access>
                <Access permission='all'><Route path='/management/b2b_results' component={Coreb2b_resultsService}/></Access>
                <Access permission='all'><Route path='/management/view/b2b_results/:id/:tab' component={Coreb2b_resultsDetailService}/></Access>
                <Access permission='all'><Route path='/management/b2c' component={Coreb2cService}/></Access>
                <Access permission='all'><Route path='/management/view/b2c/:id/:tab' component={Coreb2cDetailService}/></Access>
                <Access permission='all'><Route path='/management/b2c_archive' component={Coreb2c_archiveService}/></Access>
                <Access permission='all'><Route path='/management/view/b2c_archive/:id/:tab' component={Coreb2c_archiveDetailService}/></Access>
                <Access permission='all'><Route path='/management/b2c_results' component={Coreb2c_resultsService}/></Access>
                <Access permission='all'><Route path='/management/view/b2c_results/:id/:tab' component={Coreb2c_resultsDetailService}/></Access>
                <Access permission='all'><Route path='/management/b2c_wallet' component={Coreb2c_walletService}/></Access>
                <Access permission='all'><Route path='/management/view/b2c_wallet/:id/:tab' component={Coreb2c_walletDetailService}/></Access>
                <Access permission='all'><Route path='/management/branch' component={CorebranchService}/></Access>
                <Access permission='all'><Route path='/management/view/branch/:id/:tab' component={CorebranchDetailService}/></Access>
                <Access permission='all'><Route path='/management/c2b_confirmation' component={Corec2b_confirmationService}/></Access>
                <Access permission='all'><Route path='/management/view/c2b_confirmation/:id/:tab' component={Corec2b_confirmationDetailService}/></Access>
                <Access permission='all'><Route path='/management/c2b_wrapper' component={Corec2b_wrapperService}/></Access>
                <Access permission='all'><Route path='/management/view/c2b_wrapper/:id/:tab' component={Corec2b_wrapperDetailService}/></Access>
                <Access permission='all'><Route path='/management/c2b_wrapper_archive' component={Corec2b_wrapper_archiveService}/></Access>
                <Access permission='all'><Route path='/management/view/c2b_wrapper_archive/:id/:tab' component={Corec2b_wrapper_archiveDetailService}/></Access>
                <Access permission='all'><Route path='/management/currency' component={CorecurrencyService}/></Access>
                <Access permission='all'><Route path='/management/view/currency/:id/:tab' component={CorecurrencyDetailService}/></Access>
                <Access permission='all'><Route path='/management/customer' component={CorecustomerService}/></Access>
                <Access permission='all'><Route path='/management/view/customer/:id/:tab' component={CorecustomerDetailService}/></Access>
                <Access permission='all'><Route path='/management/dbcdr' component={CoredbcdrService}/></Access>
                <Access permission='all'><Route path='/management/view/dbcdr/:id/:tab' component={CoredbcdrDetailService}/></Access>
                <Access permission='all'><Route path='/management/dbqueue' component={CoredbqueueService}/></Access>
                <Access permission='all'><Route path='/management/view/dbqueue/:id/:tab' component={CoredbqueueDetailService}/></Access>
                <Access permission='all'><Route path='/management/dbqueue_bak' component={Coredbqueue_bakService}/></Access>
                <Access permission='all'><Route path='/management/view/dbqueue_bak/:id/:tab' component={Coredbqueue_bakDetailService}/></Access>
                <Access permission='all'><Route path='/management/eligibility_check' component={Coreeligibility_checkService}/></Access>
                <Access permission='all'><Route path='/management/view/eligibility_check/:id/:tab' component={Coreeligibility_checkDetailService}/></Access>
                <Access permission='all'><Route path='/management/entity' component={CoreentityService}/></Access>
                <Access permission='all'><Route path='/management/view/entity/:id/:tab' component={CoreentityDetailService}/></Access>
                <Access permission='all'><Route path='/management/entity_account' component={Coreentity_accountService}/></Access>
                <Access permission='all'><Route path='/management/view/entity_account/:id/:tab' component={Coreentity_accountDetailService}/></Access>
                <Access permission='all'><Route path='/management/forex' component={CoreforexService}/></Access>
                <Access permission='all'><Route path='/management/view/forex/:id/:tab' component={CoreforexDetailService}/></Access>
                <Access permission='all'><Route path='/management/forex_transaction_log' component={Coreforex_transaction_logService}/></Access>
                <Access permission='all'><Route path='/management/view/forex_transaction_log/:id/:tab' component={Coreforex_transaction_logDetailService}/></Access>
                <Access permission='all'><Route path='/management/image' component={CoreimageService}/></Access>
                <Access permission='all'><Route path='/management/view/image/:id/:tab' component={CoreimageDetailService}/></Access>
                <Access permission='all'><Route path='/management/insight_pgw_resp' component={Coreinsight_pgw_respService}/></Access>
                <Access permission='all'><Route path='/management/view/insight_pgw_resp/:id/:tab' component={Coreinsight_pgw_respDetailService}/></Access>
                <Access permission='all'><Route path='/management/language' component={CorelanguageService}/></Access>
                <Access permission='all'><Route path='/management/view/language/:id/:tab' component={CorelanguageDetailService}/></Access>
                <Access permission='all'><Route path='/management/loan_account' component={Coreloan_accountService}/></Access>
                <Access permission='all'><Route path='/management/view/loan_account/:id/:tab' component={Coreloan_accountDetailService}/></Access>
                <Access permission='all'><Route path='/management/loan_eligibility' component={Coreloan_eligibilityService}/></Access>
                <Access permission='all'><Route path='/management/view/loan_eligibility/:id/:tab' component={Coreloan_eligibilityDetailService}/></Access>
                <Access permission='all'><Route path='/management/loan_interest_earned' component={Coreloan_interest_earnedService}/></Access>
                <Access permission='all'><Route path='/management/view/loan_interest_earned/:id/:tab' component={Coreloan_interest_earnedDetailService}/></Access>
                <Access permission='all'><Route path='/management/loan_product' component={Coreloan_productService}/></Access>
                <Access permission='all'><Route path='/management/view/loan_product/:id/:tab' component={Coreloan_productDetailService}/></Access>
                <Access permission='all'><Route path='/management/loan_schedule' component={Coreloan_scheduleService}/></Access>
                <Access permission='all'><Route path='/management/view/loan_schedule/:id/:tab' component={Coreloan_scheduleDetailService}/></Access>
                <Access permission='all'><Route path='/management/mpesa_disburse' component={Corempesa_disburseService}/></Access>
                <Access permission='all'><Route path='/management/view/mpesa_disburse/:id/:tab' component={Corempesa_disburseDetailService}/></Access>
                <Access permission='all'><Route path='/management/mpesa_disburse_complete' component={Corempesa_disburse_completeService}/></Access>
                <Access permission='all'><Route path='/management/view/mpesa_disburse_complete/:id/:tab' component={Corempesa_disburse_completeDetailService}/></Access>
                <Access permission='all'><Route path='/management/next_of_kin' component={Corenext_of_kinService}/></Access>
                <Access permission='all'><Route path='/management/view/next_of_kin/:id/:tab' component={Corenext_of_kinDetailService}/></Access>
                <Access permission='all'><Route path='/management/repayment' component={CorerepaymentService}/></Access>
                <Access permission='all'><Route path='/management/view/repayment/:id/:tab' component={CorerepaymentDetailService}/></Access>
                <Access permission='all'><Route path='/management/revenue_share' component={Corerevenue_shareService}/></Access>
                <Access permission='all'><Route path='/management/view/revenue_share/:id/:tab' component={Corerevenue_shareDetailService}/></Access>
                <Access permission='all'><Route path='/management/revenue_share_log' component={Corerevenue_share_logService}/></Access>
                <Access permission='all'><Route path='/management/view/revenue_share_log/:id/:tab' component={Corerevenue_share_logDetailService}/></Access>
                <Access permission='all'><Route path='/management/savings_account' component={Coresavings_accountService}/></Access>
                <Access permission='all'><Route path='/management/view/savings_account/:id/:tab' component={Coresavings_accountDetailService}/></Access>
                <Access permission='all'><Route path='/management/savings_interest_earned' component={Coresavings_interest_earnedService}/></Access>
                <Access permission='all'><Route path='/management/view/savings_interest_earned/:id/:tab' component={Coresavings_interest_earnedDetailService}/></Access>
                <Access permission='all'><Route path='/management/savings_interest_payment' component={Coresavings_interest_paymentService}/></Access>
                <Access permission='all'><Route path='/management/view/savings_interest_payment/:id/:tab' component={Coresavings_interest_paymentDetailService}/></Access>
                <Access permission='all'><Route path='/management/savings_product' component={Coresavings_productService}/></Access>
                <Access permission='all'><Route path='/management/view/savings_product/:id/:tab' component={Coresavings_productDetailService}/></Access>
                <Access permission='all'><Route path='/management/status' component={CorestatusService}/></Access>
                <Access permission='all'><Route path='/management/view/status/:id/:tab' component={CorestatusDetailService}/></Access>
                <Access permission='all'><Route path='/management/suspense' component={CoresuspenseService}/></Access>
                <Access permission='all'><Route path='/management/view/suspense/:id/:tab' component={CoresuspenseDetailService}/></Access>
                <Access permission='all'><Route path='/management/transaction_log' component={Coretransaction_logService}/></Access>
                <Access permission='all'><Route path='/management/view/transaction_log/:id/:tab' component={Coretransaction_logDetailService}/></Access>
                <Access permission='all'><Route path='/management/user' component={CoreuserService}/></Access>
                <Access permission='all'><Route path='/management/view/user/:id/:tab' component={CoreuserDetailService}/></Access>

                {/*<Redirect to='/home' />*/}

            </div>

        );
    }
}

export function getMenuList() {
    return {
        title: 'SOS-Buka',
        menu: [
            {
                name: 'Home',
                icon: <AutoModeIcon size={20}/>,
                link: '/home',
                access: 'all',
            },
            {
                name: 'BI',
                icon: <Activity size={20}/>,
                link: '/',
                access: 'VIEW_ANALYTICS',
                children: [
                    {
                        name: 'Executive Analytics',
                        icon: <Grid size={20}/>,
                        link: '/management/executive_analytics',
                        access: 'VIEW_EXECUTIVE_ANALYTICS',
                    },
                    {
                        name: 'Analytics',
                        icon: <Grid size={20}/>,
                        link: '/management/analytics',
                        access: 'VIEW_ANALYTICS',
                    },
                    {
                        name: 'Analytics2',
                        icon: <Grid size={20}/>,
                        link: '/management/analytics2',
                        access: 'VIEW_ANALYTICS',
                    },
                ]
            },
            {
                name: 'UserManagement',
                icon: <Users size={20}/>,
                link: '/management/focus/customer',
                access: 'VIEW_CUSTOMERS',
                children: [

                    {
                        name: 'Customers',
                        link: '/management/focus/customer',
                        icon: <GroupsIcon size={20}/>,
                        // access: 'all',
                        access: 'VIEW_CUSTOMERS'
                    },
                    {
                        name: 'Officials',
                        link: '/management/focus/officials',
                        icon: <GroupsIcon size={20}/>,
                        // access: 'all',
                        access: 'VIEW_OFFICIALS'
                    },
                ]
            },

            {
                name: 'Savings',
                link: '/management/focus/savings_account',
                icon: <GroupWorkIcon size={20}/>,
                // access: 'all',
                access: 'VIEW_SAVINGS'
            },
            {
                name: 'Loans',
                link: '/management/focus/loan_account',
                icon: <CreditScoreIcon size={20}/>,
                // access: 'all',
                access: 'VIEW_LOANS'
            },

            {
                name: 'Configurations',
                icon: <PermDataSettingIcon size={20}/>,
                link: '/management/focus/loan_product',
                access: 'VIEW_CONFIGURATIONS',
                children: [
                    {
                        name: 'Eligibility',
                        link: '/management/focus/loan_eligibility',
                        icon: <CreditScoreIcon size={20}/>,
                        // access: 'all',
                        access: 'VIEW_LOAN_ELIGIBILITY'
                    },
                    {
                        name: 'Loan Product Rates',
                        link: '/management/focus/loan_product',
                        icon: <EditIcon size={20}/>,
                        // access: 'all',
                        access: 'VIEW_LOAN_PRODUCT'
                    },
                    {
                        name: 'Saving Product Rates',
                        link: '/management/focus/savings_product',
                        icon: <PermDataSettingIcon size={20}/>,
                        // access: 'all',
                        access: 'VIEW_SAVINGS_PRODUCT'
                    },
                ]
            },


            {
                name: 'Modules',
                icon: <Grid size={20}/>,
                link: '/modules',
                access: 'ALL_MODULES',
                children: [
                    {name: 'account_cummulative',link: '/management/account_cummulative',access: 'all'},
                    {name: 'agent_type',link: '/management/agent_type',access: 'all'},
                    {name: 'api_activity_log',link: '/management/api_activity_log',access: 'all'},
                    {name: 'api_user',link: '/management/api_user',access: 'all'},
                    {name: 'b2b_results',link: '/management/b2b_results',access: 'all'},
                    {name: 'b2c',link: '/management/b2c',access: 'all'},
                    {name: 'b2c_archive',link: '/management/b2c_archive',access: 'all'},
                    {name: 'b2c_results',link: '/management/b2c_results',access: 'all'},
                    {name: 'b2c_wallet',link: '/management/b2c_wallet',access: 'all'},
                    {name: 'branch',link: '/management/branch',access: 'all'},
                    {name: 'c2b_confirmation',link: '/management/c2b_confirmation',access: 'all'},
                    {name: 'c2b_wrapper',link: '/management/c2b_wrapper',access: 'all'},
                    {name: 'c2b_wrapper_archive',link: '/management/c2b_wrapper_archive',access: 'all'},
                    {name: 'currency',link: '/management/currency',access: 'all'},
                    {name: 'customer',link: '/management/customer',access: 'all'},
                    {name: 'dbcdr',link: '/management/dbcdr',access: 'all'},
                    {name: 'dbqueue',link: '/management/dbqueue',access: 'all'},
                    {name: 'dbqueue_bak',link: '/management/dbqueue_bak',access: 'all'},
                    {name: 'eligibility_check',link: '/management/eligibility_check',access: 'all'},
                    {name: 'entity',link: '/management/entity',access: 'all'},
                    {name: 'entity_account',link: '/management/entity_account',access: 'all'},
                    {name: 'forex',link: '/management/forex',access: 'all'},
                    {name: 'forex_transaction_log',link: '/management/forex_transaction_log',access: 'all'},
                    {name: 'image',link: '/management/image',access: 'all'},
                    {name: 'insight_pgw_resp',link: '/management/insight_pgw_resp',access: 'all'},
                    {name: 'language',link: '/management/language',access: 'all'},
                    {name: 'loan_account',link: '/management/loan_account',access: 'all'},
                    {name: 'loan_eligibility',link: '/management/loan_eligibility',access: 'all'},
                    {name: 'loan_interest_earned',link: '/management/loan_interest_earned',access: 'all'},
                    {name: 'loan_product',link: '/management/loan_product',access: 'all'},
                    {name: 'loan_schedule',link: '/management/loan_schedule',access: 'all'},
                    {name: 'mpesa_disburse',link: '/management/mpesa_disburse',access: 'all'},
                    {name: 'mpesa_disburse_complete',link: '/management/mpesa_disburse_complete',access: 'all'},
                    {name: 'next_of_kin',link: '/management/next_of_kin',access: 'all'},
                    {name: 'repayment',link: '/management/repayment',access: 'all'},
                    {name: 'revenue_share',link: '/management/revenue_share',access: 'all'},
                    {name: 'revenue_share_log',link: '/management/revenue_share_log',access: 'all'},
                    {name: 'savings_account',link: '/management/savings_account',access: 'all'},
                    {name: 'savings_interest_earned',link: '/management/savings_interest_earned',access: 'all'},
                    {name: 'savings_interest_payment',link: '/management/savings_interest_payment',access: 'all'},
                    {name: 'savings_product',link: '/management/savings_product',access: 'all'},
                    {name: 'status',link: '/management/status',access: 'all'},
                    {name: 'suspense',link: '/management/suspense',access: 'all'},
                    {name: 'transaction_log',link: '/management/transaction_log',access: 'all'},
                    {name: 'user',link: '/management/user',access: 'all'}
                ],
            },


            {
                name: 'Administration',
                icon: <Shield size={20}/>,
                link: '/administration/admins',
                access: 'ADMIN_READ',
                children: [
                    {
                        name: 'Admins',
                        link: '/administration/admins',
                        access: 'ADMIN_READ',
                    },
                    {
                        name: 'Maker Checker',
                        link: '/administration/maker-checker',
                        access: 'MAKER_CHECKER_READ',
                    },
                    {
                        name: 'IP Adresses',
                        icon: <Code size={20}/>,
                        link: '/administration/ip',
                        access: 'IP_ADDRESS_READ',
                    },
                    {
                        name: 'Audit',
                        icon: <UserCheck size={20}/>,
                        link: '/administration/audit',
                        access: 'AUDIT_READ',
                    },

                    {
                        name: 'Roles',
                        link: '/administration/roles',
                        access: 'ROLES_READ',
                    },
                ],
            },
        ],
        currentRoute: window.location.pathname,
        active: 0,
    }
}

  
